import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../../firebase';

import BallPath from '../game/BallPath';

export default class Putts extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    putts: null,
    ct:0
  };

  componentDidMount(){
    this.getPutts();
  }

  getPutts = async() => {
    this.setState({putts:null});

    const url = "http://localhost:8080/club/analytics/puttsByGame/383";
    axios.get(url)
      .then(res => {
        this.setState({ putts: res.data });

        console.log("length:", res.data.length)
        this.goPutts();
      })
  }

  goPutts = () => {

    setInterval(this.incrementPutts, 50 );
  }

  incrementPutts = () => {

    const c = this.state.ct + 1;
    if (c < this.state.putts.length){
      this.setState({ct:c});
    }

  }



  renderPutts = () => {

    if (this.state.putts){

      const putt = this.state.putts[this.state.ct];

      return (

        <div>

          <div style={{width:'640px'}}>

            <BallPath putt={putt} width={640} />

          </div>


        </div>

        );


    }
  }

  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <FirebaseAuthProvider firebase={firebase} {...config}>
          <div style={{ marginTop:'80px'}}>

            <IfFirebaseAuthed>
            {({ isSignedIn, user, providerId }) => {
              return (

                <div className="heading" style={{marginTop:'80px'}}>

                  <div className="row">

                    {this.renderPutts()}

                  </div>

                </div>
              );
            }}
            </IfFirebaseAuthed>

          </div>
        </FirebaseAuthProvider>

      </Container>

    );

  }

}

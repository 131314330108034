import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import logo from '../../images/logo_dark.svg';

import LeaderboardCard from './LeaderboardCard';

const perPage = 4;

export default class Leaderboard extends React.Component {

  constructor(props){
    super(props);
  }
  state = {
    leaderboards: null,
    club: null,
    currentPage: 0,
    selectedLeaderboard: null
  };
  componentDidMount(){
    this.getClubLeaderboards();
    this.getClubData();

    const cycleSec = 15 * 1000;//set interval to cycle every 30 seconds
    const refreshSec = 15 * 1000;//set interval to refresh every 5 minutes
    if (this.props.signage == 1){
      setInterval(this.cycleLeaderboards, cycleSec);
    }
    setInterval(this.getClubLeaderboards, refreshSec);
  }
  cycleLeaderboards = () => {

    //calculate current page
    let cp = this.state.currentPage + 1;//increment cp
    let lb = this.state.leaderboards;
    let tot = 0;

    for (var i=0; i < lb.length; i++){
      if (lb[i].scores.length > 0){
        tot++;
      }
    }

    //increment current page
    if ((cp * perPage) >= tot){//if next
      cp = 0;
    }
    this.setState({currentPage:cp});

  }
  getClubLeaderboards = () => {
    var url_string = window.location.href;
    var window_url = new URL(url_string);

    var days = window_url.searchParams.get("days");

    let url = "https://api.puttr.co/club/leaderboard/games/" + this.props.clubId;

    if (days){
      url += "/" + days;
    }

    axios.get(url)
      .then(res => {

        const lb = res.data;

        var gid = window_url.searchParams.get("gameId");

        if (gid){
          for (var i=0; i < lb.length; i++){
            if (lb[i].leaderboard.id == gid){
              this.setState({selectedLeaderboard:lb[i]});
              break;
            }
          }
        }

        //filter out leaderboards with no scores
        let lArr = [];
        for (var i=0; i < lb.length; i++){
          if (lb[i].scores.length > 0){
            lArr.push(lb[i]);
          }
        }

        console.log("lArr:", lArr);

        this.setState({ leaderboards: lArr });

      })
  }
  getClubData = () => {
    const url = "https://api.puttr.co/club/info/" + this.props.clubId;
    axios.get(url)
      .then(res => {
        this.setState({ club:res.data[0] });
      })
  }
  selectLeaderboard = (lb) => {
    //console.log("selected leaderboard:", lb);
    this.setState({selectedLeaderboard:lb});
  }
  renderLeaderboards = () => {

    if (this.state.selectedLeaderboard){
      return(
        <LeaderboardCard
          leaderboard={this.state.selectedLeaderboard}
          clubId={this.props.clubId}
          selectLeaderboard={this.selectLeaderboard}
          fullBoard={true}
          signage={this.props.signage}
        />
      );
    }

    if (this.state.leaderboards){

      let st = this.state.currentPage * perPage;//get starting point
      let newArr = [];

      if (this.props.signage == 1){//if signage is 1

        for (var i=st;i<st+perPage;i++){
          if (this.state.leaderboards[i])
            newArr.push(this.state.leaderboards[i]);
        }
      }

      else {

        for (var i=0;i<this.state.leaderboards.length;i++){
          if (this.state.leaderboards[i])
            newArr.push(this.state.leaderboards[i]);
        }
      }

      return newArr.map((leaderboard, index) => {

        if (leaderboard.scores.length > 0) {
          return (
            <LeaderboardCard
              key={index}
              leaderboard={leaderboard}
              clubId={this.props.clubId}
              signage={this.props.signage}
              selectLeaderboard={this.selectLeaderboard}
            />
          );

        }

      });

    }

    return(
      <div className="heading">
        <div className="title">LOADING LEADERBOARD...</div>
      </div>
    );

  }
  renderClubInfo = () => {

    if (this.state.club){

      if (this.state.club.clubLogo){

        let hc = '#fff';
        if (this.state.club.headerColor){
          hc = "#" + this.state.club.headerColor;
        }

        return(

          <div className="prow" style={{backgroundColor:hc, padding:10}}>

            <img src={this.state.club.clubLogo} width="300" height="80" alt="Logo" />

          </div>

        );
      }
    }
  }
  renderQrCode = () => {

    if (this.props.signage == 1){//if signage is 1

      const regUrl = "https://app.puttr.co/leaderboard/" + this.props.clubId;
      const qrPath = "https://api.qrserver.com/v1/create-qr-code/?data="+regUrl+"&amp;size=100x100"

      return(
        <div className="qrCode">

          <img src={qrPath} width="100" height="100" alt="QR Code" />

        </div>
      );
    }
  }
  render(){

    return(

      <Container fluid style={{backgroundColor:'#F4F4F4'}} className="align-items-center justify-content-center">

        {this.renderClubInfo()}

        <div className="prow" style={{marginTop:'20px'}}>

          <div className="title">LEADERBOARD</div>

        </div>

        <div style={{marginTop:'30px'}} className="prow">

          {this.renderLeaderboards()}

        </div>

        {this.renderQrCode()}

        <div style={{marginTop:'30px'}} className="prow">

          <a href="https://puttr.co?utm_source=leaderboard" target="_blank">

          <img src={logo} color='#000' width="180" height="50" className="align-top" alt="PUTTR Logo" />

          </a>

        </div>

      </Container>

    );

  }

}
